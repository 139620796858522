import React from 'react';
import { Helmet } from 'react-helmet';
import EkoAnalytics from 'we-sdk/components/EkoAnalytics';

import '../styles/scss/components/layout.scss';

export default function MinisiteLayout({ children }) {
    return (
        <>
            <EkoAnalytics
                isProduction={typeof window !== 'undefined' && (window.location.hostname === 'giftfinder.eko.com' || window.location.hostname === 'productfinder.eko.com') }
                isKidsContent={false}
                appId='giftfinder-minisite-app'
                vwoEnabled={true}
            />
            <div className='video-container-gift-finder'>
                {children}
            </div>
        </>
    );
}
