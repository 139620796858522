import React from "react";
import DefaultLayout from "./default";
import MinisiteLayout from "./minisite";

// NOTE: this is implemented using https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
// When we generate toys pages in gatsby-node.js, we pass the layout type on the page context,
// for the static pages (or if a different layout is passed on the page context), we return the empty component as a layout.
export default ({ children, pageContext }) => {
  if (pageContext.layout === "minisite") {
    return <MinisiteLayout>{children}</MinisiteLayout>;
  }

  return <DefaultLayout pageContext={pageContext}>{children}</DefaultLayout>;
};
