import React from 'react';
import { Helmet } from 'react-helmet';
import EkoAnalytics from 'we-sdk/components/EkoAnalytics';

import '../styles/scss/components/layout.scss';

export default function DefaultLayout({ children, pageContext }) {
    return (
        <>
            <EkoAnalytics
                isProduction={typeof window !== 'undefined' && (window.location.hostname === 'giftfinder.eko.com' || window.location.hostname === 'productfinder.eko.com')}
                isKidsContent={false}
                appId='productfinder-app'
                vwoEnabled={true}
                projectId={pageContext.ekoId}
            />
            <div className='video-container'>
                {children}
            </div>
        </>
    );
} 
